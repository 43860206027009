const _jsxFileName = "/parabol/packages/client/components/InputField/BasicTextArea.tsx";import styled from '@emotion/styled'
import React from 'react'
import makeFieldColorPalette from '../../styles/helpers/makeFieldColorPalette'
import ui from '../../styles/ui'
import StyledError from '../StyledError'

const TextArea = styled('textarea')({
  ...ui.fieldBaseStyles,
  ...ui.fieldSizeStyles.medium,
  ...makeFieldColorPalette('white'),
  minHeight: '5.75rem'
})












const BasicTextArea = (props) => {
  const {autoFocus, disabled, error, name, onBlur, onChange, placeholder, value} = props

  return (
    React.createElement(React.Fragment, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 29}}
      , React.createElement(TextArea, {
        autoFocus: autoFocus,
        disabled: disabled,
        name: name,
        placeholder: placeholder,
        onBlur: onBlur,
        onChange: onChange,
        value: value, __self: this, __source: {fileName: _jsxFileName, lineNumber: 30}}
      )
      , error && React.createElement(StyledError, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 39}}, error)
    )
  )
}

export default BasicTextArea
