const _jsxFileName = "/parabol/packages/client/Root.tsx";import React from 'react'
import {BrowserRouter as Router} from 'react-router-dom'
import Action from './components/Action/Action'
import AtmosphereProvider from './components/AtmosphereProvider/AtmosphereProvider'

export default function Root() {
  return (
    React.createElement(AtmosphereProvider, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 8}}
      , React.createElement(Router, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 9}}
        , React.createElement(Action, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 10}} )
      )
    )
  )
}
