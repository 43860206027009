export var FONT_FAMILY; (function (FONT_FAMILY) {
  const MONOSPACE = '"IBM Plex Mono", Menlo, Monaco, Consolas, "Courier New", monospace'; FONT_FAMILY["MONOSPACE"] = MONOSPACE;
  const SANS_SERIF = '"IBM Plex Sans", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif'; FONT_FAMILY["SANS_SERIF"] = SANS_SERIF;
})(FONT_FAMILY || (FONT_FAMILY = {}));

export var ICON_SIZE; (function (ICON_SIZE) {
  const MD18 = '18px'; ICON_SIZE["MD18"] = MD18;
  const MD24 = '24px'; ICON_SIZE["MD24"] = MD24;
  const MD36 = '36px'; ICON_SIZE["MD36"] = MD36;
  const MD40 = '40px'; ICON_SIZE["MD40"] = MD40;
  const MD48 = '48px'; ICON_SIZE["MD48"] = MD48;
})(ICON_SIZE || (ICON_SIZE = {}));
