const _jsxFileName = "/parabol/packages/client/components/ErrorComponent/ErrorComponent.tsx";import styled from '@emotion/styled'
import React from 'react'
import PrimaryButton from '~/components/PrimaryButton'
import ReportErrorFeedback from '~/components/ReportErrorFeedback'
import useModal from '~/hooks/useModal'
import {isOldBrowserError} from '~/utils/isOldBrowserError'

const ErrorBlock = styled('div')({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  textAlign: 'center',
  width: '100%',
  height: '100%'
})

const Button = styled(PrimaryButton)({
  marginTop: 8
})

const Link = styled('a')({
  color: 'inherit'
})






const ErrorComponent = (props) => {
  const {error, eventId} = props
  console.error(error)
  const {modalPortal, openPortal, closePortal} = useModal()
  const isOldBrowserErr = isOldBrowserError(error.message)

  if (isOldBrowserErr) {
    const url = 'https://browser-update.org/update-browser.html'
    return (
      React.createElement(ErrorBlock, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 40}}
        , "Oh no! You've found a bug because the browser you're using needs to be updated."
        , React.createElement(Button, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 42}}
          , React.createElement(Link, { href: url, target: "_blank", rel: "noreferrer", __self: this, __source: {fileName: _jsxFileName, lineNumber: 43}}, "Update now"

          )
        )
      )
    )
  }
  return (
    React.createElement(ErrorBlock, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 51}}
      , 'An error has occurred! We’ve alerted the developers. Try refreshing the page'
      , eventId && React.createElement(Button, { onClick: openPortal, __self: this, __source: {fileName: _jsxFileName, lineNumber: 53}}, "Report Feedback" )
      , modalPortal(React.createElement(ReportErrorFeedback, { closePortal: closePortal, eventId: eventId, __self: this, __source: {fileName: _jsxFileName, lineNumber: 54}} ))
    )
  )
}

export default ErrorComponent
