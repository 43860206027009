const _jsxFileName = "/parabol/packages/client/components/PrivateRoutes.tsx";import React, {lazy} from 'react'
import {Route, Switch} from 'react-router'
import useAuthRoute from '../hooks/useAuthRoute'
import useNoIndex from '../hooks/useNoIndex'

const Invoice = lazy(
  () => import(/* webpackChunkName: 'InvoiceRoot' */ '../modules/invoice/containers/InvoiceRoot')
)
const NewMeetingSummary = lazy(
  () =>
    import(
      /* webpackChunkName: 'NewMeetingSummaryRoot' */ '../modules/summary/components/NewMeetingSummaryRoot'
    )
)
const Graphql = lazy(
  () =>
    import(
      /* webpackChunkName: 'GraphqlContainer' */ '../modules/admin/containers/Graphql/GraphqlContainer'
    )
)
const Impersonate = lazy(
  () =>
    import(
      /* webpackChunkName: 'ImpersonateContainer' */ '../modules/admin/containers/Impersonate/ImpersonateContainer'
    )
)
const Signout = lazy(
  () => import(/* webpackChunkName: 'SignoutContainer' */ '../containers/Signout/SignoutContainer')
)
const NotFound = lazy(() => import(/* webpackChunkName: 'NotFound' */ './NotFound/NotFound'))
const DashboardRoot = lazy(() => import(/* webpackChunkName: 'DashboardRoot' */ './DashboardRoot'))
const MeetingRoot = lazy(() => import(/* webpackChunkName: 'MeetingRoot' */ './MeetingRoot'))
const ViewerNotOnTeamRoot = lazy(
  () => import(/* webpackChunkName: 'ViewerNotOnTeamRoot' */ './ViewerNotOnTeamRoot')
)

const PrivateRoutes = () => {
  useAuthRoute()
  useNoIndex()
  return (
    React.createElement(Switch, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 41}}
      , React.createElement(Route, { path: "(/meetings|/me|/newteam|/team|/usage|/new-meeting)", component: DashboardRoot, __self: this, __source: {fileName: _jsxFileName, lineNumber: 42}} )
      , React.createElement(Route, { path: "/meet/:meetingId", component: MeetingRoot, __self: this, __source: {fileName: _jsxFileName, lineNumber: 43}} )
      , React.createElement(Route, { path: "/invoice/:invoiceId", component: Invoice, __self: this, __source: {fileName: _jsxFileName, lineNumber: 44}} )
      , React.createElement(Route, { path: "/new-summary/:meetingId/:urlAction?", component: NewMeetingSummary, __self: this, __source: {fileName: _jsxFileName, lineNumber: 45}} )
      , React.createElement(Route, { path: "/admin/graphql", component: Graphql, __self: this, __source: {fileName: _jsxFileName, lineNumber: 46}} )
      , React.createElement(Route, { path: "/admin/impersonate", component: Impersonate, __self: this, __source: {fileName: _jsxFileName, lineNumber: 47}} )
      , React.createElement(Route, { path: "/invitation-required", component: ViewerNotOnTeamRoot, __self: this, __source: {fileName: _jsxFileName, lineNumber: 48}} )
      , React.createElement(Route, { path: "/signout", component: Signout, __self: this, __source: {fileName: _jsxFileName, lineNumber: 49}} )
      , React.createElement(Route, { component: NotFound, __self: this, __source: {fileName: _jsxFileName, lineNumber: 50}} )
    )
  )
}

export default PrivateRoutes
