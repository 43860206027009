const _jsxFileName = "/parabol/packages/client/components/SnackbarMessageAction.tsx";import styled from '@emotion/styled'
import React from 'react'
import {DECELERATE} from '../styles/animation'
import {PALETTE} from '../styles/paletteV3'
import {Radius} from '../types/constEnums'
import PlainButton from './PlainButton/PlainButton'






const Action = styled(PlainButton)({
  borderRadius: Radius.SNACKBAR,
  color: PALETTE.ROSE_500,
  fontSize: 14,
  fontWeight: 600,
  marginLeft: 8,
  padding: 8,
  whiteSpace: 'nowrap',
  backgroundColor: '#ffffff17',
  transition: `background 100ms ${DECELERATE}`,
  ':hover,:focus,:active': {
    backgroundColor: '#ffffff26'
  }
})

const SnackbarMessageAction = (props) => {
  const {action} = props
  if (!action) return null
  const {label, callback} = action
  return React.createElement(Action, { onClick: callback, __self: this, __source: {fileName: _jsxFileName, lineNumber: 32}}, label)
}

export default SnackbarMessageAction
