// Note: Not all weight variants of the expanded palette are available.
//       We only add variables when new variants are used in design.
//       As a design choice, we use constraint when adding weights from the expanded palette.
//       We try to only use the limited palette, but weighted variants
//       can help with subtle color changes in states like hover, or disabled.
//       The limited palette is here:
//       https://www.figma.com/file/OA9NkpSTlHVqqRL9IE9KsF/Palette-v3?node-id=15%3A366
//       The expanded palette is here:
//       https://www.figma.com/file/OA9NkpSTlHVqqRL9IE9KsF/Palette-v3?node-id=15%3A2184

export var PALETTE; (function (PALETTE) {
  const AQUA_400 = '#55C0CF'; PALETTE["AQUA_400"] = AQUA_400;

  const FOREST_400 = '#73A153'; PALETTE["FOREST_400"] = FOREST_400;

  const FUSCIA_400 = '#DB70DB'; PALETTE["FUSCIA_400"] = FUSCIA_400;

  const GOLD_100 = '#FAEBD3'; PALETTE["GOLD_100"] = GOLD_100;
  const GOLD_200 = '#FADC9E'; PALETTE["GOLD_200"] = GOLD_200;
  const GOLD_300 = '#FFCC63'; PALETTE["GOLD_300"] = GOLD_300;
  const GOLD_500 = '#DE8E02'; PALETTE["GOLD_500"] = GOLD_500;
  const GOLD_700 = '#855000'; PALETTE["GOLD_700"] = GOLD_700;
  const GOLD_HIGHLIGHT = '#FBDA95'; PALETTE["GOLD_HIGHLIGHT"] = GOLD_HIGHLIGHT;

  const GRADIENT_TOMATO_600_ROSE_500 = 'linear-gradient(to right, #F23B31 0, #ED4C86 100%)'; PALETTE["GRADIENT_TOMATO_600_ROSE_500"] = GRADIENT_TOMATO_600_ROSE_500; // default: TOMATO_600 to ROSE_500
  const GRADIENT_TOMATO_700_ROSE_600 = 'linear-gradient(to right, #D5211A 0, #EB195F 100%)'; PALETTE["GRADIENT_TOMATO_700_ROSE_600"] = GRADIENT_TOMATO_700_ROSE_600; // hover: TOMATO_700 to ROSE_600
  const GRADIENT_TOMATO_400_ROSE_300 = 'linear-gradient(to right, #FD7F77 0, #F099BF 100%)'; PALETTE["GRADIENT_TOMATO_400_ROSE_300"] = GRADIENT_TOMATO_400_ROSE_300; // disabled: TOMATO_400 to ROSE_300

  const GRAPE_500_30 = '#A06BD64D'; PALETTE["GRAPE_500_30"] = GRAPE_500_30;

  const GRAPE_500 = '#A06BD6'; PALETTE["GRAPE_500"] = GRAPE_500;
  const GRAPE_600 = '#7340B5'; PALETTE["GRAPE_600"] = GRAPE_600;
  const GRAPE_700 = '#493272'; PALETTE["GRAPE_700"] = GRAPE_700;
  const GRAPE_800 = '#2D1D53'; PALETTE["GRAPE_800"] = GRAPE_800;

  const GRASS_300 = '#C4CF66'; PALETTE["GRASS_300"] = GRASS_300;

  const JADE_400_30 = '#66BC8C4D'; PALETTE["JADE_400_30"] = JADE_400_30;

  const JADE_400 = '#66BC8C'; PALETTE["JADE_400"] = JADE_400;
  const JADE_300 = '#91E8B7'; PALETTE["JADE_300"] = JADE_300;

  const LILAC_500 = '#7272E5'; PALETTE["LILAC_500"] = LILAC_500;

  const ROSE_500 = '#ED4C86'; PALETTE["ROSE_500"] = ROSE_500;

  const SKY_300 = '#8EC7F1'; PALETTE["SKY_300"] = SKY_300;
  const SKY_400 = '#61B1EB'; PALETTE["SKY_400"] = SKY_400;
  const SKY_500_30 = '#329AE54D'; PALETTE["SKY_500_30"] = SKY_500_30;
  const SKY_500 = '#329AE5'; PALETTE["SKY_500"] = SKY_500;
  const SKY_600 = '#14649E'; PALETTE["SKY_600"] = SKY_600;

  const SLATE_100 = '#F8F7FC'; PALETTE["SLATE_100"] = SLATE_100;
  const SLATE_200 = '#F1F0FA'; PALETTE["SLATE_200"] = SLATE_200;
  const SLATE_300 = '#E0DDEC'; PALETTE["SLATE_300"] = SLATE_300;
  const SLATE_400 = '#C3C0D8'; PALETTE["SLATE_400"] = SLATE_400;
  const SLATE_500 = '#A7A3C2'; PALETTE["SLATE_500"] = SLATE_500;
  const SLATE_600 = '#82809A'; PALETTE["SLATE_600"] = SLATE_600;
  const SLATE_700 = '#444258'; PALETTE["SLATE_700"] = SLATE_700;
  const SLATE_800 = '#2D2D39'; PALETTE["SLATE_800"] = SLATE_800;
  const SLATE_900 = '#1C1C21'; PALETTE["SLATE_900"] = SLATE_900;

  const SLATE_700_30 = '#4442584D'; PALETTE["SLATE_700_30"] = SLATE_700_30; // SLATE_700 at 30% opacity
  const SLATE_700_80 = '#444258CD'; PALETTE["SLATE_700_80"] = SLATE_700_80; // SLATE_700 at ~80% opacity
  const SLATE_900_32 = '#1C1C2152'; PALETTE["SLATE_900_32"] = SLATE_900_32; // SLATE_900 at 32% opacity

  const TERRA_300 = '#FE975D'; PALETTE["TERRA_300"] = TERRA_300;
  const TERRA_500 = '#D35D22'; PALETTE["TERRA_500"] = TERRA_500;

  const TOMATO_100 = '#FFE2E0'; PALETTE["TOMATO_100"] = TOMATO_100;
  const TOMATO_200 = '#FFC1BD'; PALETTE["TOMATO_200"] = TOMATO_200;
  const TOMATO_400 = '#FD7F77'; PALETTE["TOMATO_400"] = TOMATO_400;
  const TOMATO_500 = '#FD6157'; PALETTE["TOMATO_500"] = TOMATO_500;
  const TOMATO_600 = '#F23B31'; PALETTE["TOMATO_600"] = TOMATO_600;
  const TOMATO_700 = '#D5211A'; PALETTE["TOMATO_700"] = TOMATO_700;
  const TOMATO_800 = '#9F201E'; PALETTE["TOMATO_800"] = TOMATO_800;

  const WHITE = '#FFFFFF'; PALETTE["WHITE"] = WHITE;

  const SUCCESS_LIGHT = '#2db553'; PALETTE["SUCCESS_LIGHT"] = SUCCESS_LIGHT;
})(PALETTE || (PALETTE = {}));
