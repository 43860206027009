const _jsxFileName = "/parabol/packages/client/components/Action/Action.tsx";import {css, Global} from '@emotion/core'
import React, {lazy, memo, Suspense} from 'react'
import {Route, Switch} from 'react-router'
import useServiceWorkerUpdater from '../../hooks/useServiceWorkerUpdater'
import useTrebuchetEvents from '../../hooks/useTrebuchetEvents'
import globalStyles from '../../styles/theme/globalStyles'
import {LoaderSize} from '../../types/constEnums'
import {CREATE_ACCOUNT_SLUG, SIGNIN_SLUG} from '../../utils/constants'
import ErrorBoundary from '../ErrorBoundary'
import LoadingComponent from '../LoadingComponent/LoadingComponent'
import PrivateRoutes from '../PrivateRoutes'
import Snackbar from '../Snackbar'

const AnalyticsPage = lazy(() => import(/* webpackChunkName: 'AnalyticsPage' */ '../AnalyticsPage'))
const AuthenticationPage = lazy(
  () => import(/* webpackChunkName: 'AuthenticationPage' */ '../AuthenticationPage')
)
const DemoMeeting = lazy(() => import(/* webpackChunkName: 'DemoMeeting' */ '../DemoMeeting'))
const DemoSummary = lazy(() => import(/* webpackChunkName: 'DemoSummary' */ '../DemoSummary'))
const AuthProvider = lazy(() => import(/* webpackChunkName: 'AuthProvider' */ '../AuthProvider'))
const SAMLRedirect = lazy(() => import(/* webpackChunkName: 'SAMLRedirect' */ '../SAMLRedirect'))
const SetNewPassword = lazy(
  () => import(/* webpackChunkName: 'SetNewPassword' */ '../ResetPasswordPage/SetNewPassword')
)
const VerifyEmail = lazy(() => import(/* webpackChunkName: 'VerifyEmail' */ '../VerifyEmail'))
const TeamInvitation = lazy(
  () => import(/* webpackChunkName: 'TeamInvitationRoot' */ '../TeamInvitationRoot')
)
const InvitationLink = lazy(
  () => import(/* webpackChunkName: 'InvitationLinkRoot' */ '../InvitationLinkRoot')
)

const Action = memo(() => {
  useTrebuchetEvents()
  useServiceWorkerUpdater()
  const isInternalAuthEnabled = window.__ACTION__.AUTH_INTERNAL_ENABLED
  return (
    React.createElement(React.Fragment, null
      , React.createElement(Global, {
        styles: css`
          ${globalStyles}
        `, __self: this, __source: {fileName: _jsxFileName, lineNumber: 39}}
      )
      , React.createElement(ErrorBoundary, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 44}}
        , React.createElement(Snackbar, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 45}} )
        , React.createElement(Suspense, { fallback: React.createElement(LoadingComponent, { spinnerSize: LoaderSize.WHOLE_PAGE, __self: this, __source: {fileName: _jsxFileName, lineNumber: 46}} ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 46}}
          , React.createElement(AnalyticsPage, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 47}} )
          , React.createElement(Switch, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 48}}
            , React.createElement(Route, { exact: true, path: "/", render: (p) => React.createElement(AuthenticationPage, { ...p, page: 'signin', __self: this, __source: {fileName: _jsxFileName, lineNumber: 49}} ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 49}} )
            , React.createElement(Route, {
              exact: true,
              path: `/${SIGNIN_SLUG}`,
              render: (p) => React.createElement(AuthenticationPage, { ...p, page: 'signin', __self: this, __source: {fileName: _jsxFileName, lineNumber: 53}} ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 50}}
            )
            , React.createElement(Route, {
              exact: true,
              path: `/${CREATE_ACCOUNT_SLUG}`,
              render: (p) => React.createElement(AuthenticationPage, { ...p, page: 'create-account', __self: this, __source: {fileName: _jsxFileName, lineNumber: 58}} ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 55}}
            )
            , React.createElement(Route, { exact: true, path: `/auth/:provider`, component: AuthProvider, __self: this, __source: {fileName: _jsxFileName, lineNumber: 60}} )
            , React.createElement(Route, { path: `/saml-redirect`, component: SAMLRedirect, __self: this, __source: {fileName: _jsxFileName, lineNumber: 61}} )
            , React.createElement(Route, {
              path: "/retrospective-demo/:localPhaseSlug?/:stageIdxSlug?",
              component: DemoMeeting, __self: this, __source: {fileName: _jsxFileName, lineNumber: 62}}
            )
            , React.createElement(Route, { path: "/retrospective-demo-summary/:urlAction?", component: DemoSummary, __self: this, __source: {fileName: _jsxFileName, lineNumber: 66}} )
            , isInternalAuthEnabled && (
              React.createElement(Route, {
                exact: true,
                path: `/forgot-password`,
                render: (p) => React.createElement(AuthenticationPage, { ...p, page: 'forgot-password', __self: this, __source: {fileName: _jsxFileName, lineNumber: 71}} ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 68}}
              )
            )
            , isInternalAuthEnabled && (
              React.createElement(Route, {
                path: `/forgot-password/submitted`,
                render: (p) => React.createElement(AuthenticationPage, { ...p, page: `forgot-password/submitted`, __self: this, __source: {fileName: _jsxFileName, lineNumber: 77}} ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 75}}
              )
            )
            , React.createElement(Route, {
              path: "/verify-email/:verificationToken/:invitationToken?",
              component: VerifyEmail, __self: this, __source: {fileName: _jsxFileName, lineNumber: 80}}
            )
            , React.createElement(Route, { path: "/reset-password/:token", component: SetNewPassword, __self: this, __source: {fileName: _jsxFileName, lineNumber: 84}} )
            , React.createElement(Route, { path: "/team-invitation/:token", component: TeamInvitation, __self: this, __source: {fileName: _jsxFileName, lineNumber: 85}} )
            , React.createElement(Route, { path: "/invitation-link/:token", component: InvitationLink, __self: this, __source: {fileName: _jsxFileName, lineNumber: 86}} )
            , React.createElement(Route, { component: PrivateRoutes, __self: this, __source: {fileName: _jsxFileName, lineNumber: 87}} )
          )
        )
      )
    )
  )
})

export default Action
